// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { baseEnvironment } from 'src/environments/baseEnvironment.preprod';

export const environment = {
  ...baseEnvironment,
  organizationPrefix: 'CAV',
  organizationUUID: '3850749f-37c1-451a-846d-4d89ec01928b',
  activeLanguage: 'en-US',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related message stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an message is thrown.
 */
// import 'zone.js/plugins/zone-message';  // Included with Angular CLI.
